.kuiembed-container {
  background-color: #fff;
  border: 0;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  margin: auto;
  max-width: 30em;
  padding: 4rem 3rem;
  width: 100%;
}

.kuiembed-container * {
  box-sizing: border-box;
}

.kuiembed-label {
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.kuiembed-container fieldset {
  border: 0;
  padding: 0;
}

.kuiembed-field {
  margin-bottom: 2rem;
  width: 100%;
}

.kuiembed-field input {
  background-color: #f6f6f6;
  border: 0;
  border-radius: 8px;
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
}

.kuiembed-heading {
  background-color: #fff;
  display: block;
  font-size: 2rem;
  padding-bottom: 1rem;
  width: 100%;
}

.kuiembed-sub-heading {
  display: block;
  font-size: 1rem;
  padding-bottom: 2em;
}

.kuiembed-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
}

.kuiembed-hide-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
  -webkit-clip-path: inset(50%) !important;
}

.kuiembed-button {
  background-color: #0F0F0F;
  border: 2px solid #0000;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-style: normal;
  line-height: 1;
  padding: 1rem;
  text-align: center;
  width: 100%;
}

.kuiembed-message {
  color: #636363;
  display: flex;
  justify-content: center;
}

.kuiembed-message--error {
  color: #9B0000;
}

.kuiembed-kinde-logo {
  border-top: 1px solid #0000001a;
  grid-row: 2 / 3;
  padding-top: 1rem;
  text-align: center;
}

.kuiembed-kinde-logo__text {
  color: #00000080;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.125rem;
}

.kuiembed-kinde-logo__icon {
  color: currentColor;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 4.375rem;
}
