.kuiembed-container {
  box-sizing: border-box;
  max-width: 30em;
  width: 100%;
  background-color: #fff;
  border: 0;
  border-radius: 16px;
  margin: auto;
  padding: 4rem 3rem;
  font-family: Helvetica, Arial, sans-serif;
}

.kuiembed-container * {
  box-sizing: border-box;
}

.kuiembed-label {
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.kuiembed-container fieldset {
  border: 0;
  padding: 0;
}

.kuiembed-field {
  width: 100%;
  margin-bottom: 2rem;
}

.kuiembed-field input {
  width: 100%;
  background-color: #f6f6f6;
  border: 0;
  border-radius: 8px;
  padding: 1rem;
  font-size: 1rem;
}

.kuiembed-heading {
  width: 100%;
  background-color: #fff;
  padding-bottom: 1rem;
  font-size: 2rem;
  display: block;
}

.kuiembed-sub-heading {
  padding-bottom: 2em;
  font-size: 1rem;
  display: block;
}

.kuiembed-form {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  display: flex;
}

.kuiembed-hide-visually {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  white-space: nowrap !important;
  width: 1px !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.kuiembed-button {
  color: #fff;
  cursor: pointer;
  text-align: center;
  width: 100%;
  background-color: #0f0f0f;
  border: 2px solid #0000;
  border-radius: 8px;
  padding: 1rem;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.kuiembed-message {
  color: #636363;
  justify-content: center;
  display: flex;
}

.kuiembed-message--error {
  color: #9b0000;
}

.kuiembed-kinde-logo {
  text-align: center;
  border-top: 1px solid #0000001a;
  grid-row: 2 / 3;
  padding-top: 1rem;
}

.kuiembed-kinde-logo__text {
  color: #00000080;
  margin-bottom: .125rem;
  font-size: .875rem;
  font-weight: 500;
}

.kuiembed-kinde-logo__icon {
  color: currentColor;
  width: 4.375rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/*# sourceMappingURL=subscribe.css.map */
